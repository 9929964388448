<template>
    <div id="search-component">
      <button @click="translate" class="button-style">翻译</button>
      <button @click="domesticSearch" class="button-style">国内搜索</button>
      <button @click="overseaSearch" class="button-style">综合搜索</button>
      <button @click="search" class="button-style">GO</button>
      <input
        textarea="text"
        v-model="searchText"
        @keyup.enter="search"
        placeholder="输入搜索词"
        class="input-style"
      />
  
      <div>
        <label for="baidu-search" class="checkbox-style">
          <input type="checkbox" id="baidu-search" v-model="searchBaidu">
          百度
        </label>
  
        <label for="bing-search" class="checkbox-style">
          <input type="checkbox" id="bing-search" v-model="searchBing">
          Bing
        </label>
  
        <label for="zhihu-search" class="checkbox-style">
          <input type="checkbox" id="zhihu-search" v-model="searchZhihu">
          知乎
        </label>
  
        <label for="metaso-AI" class="checkbox-style">
          <input type="checkbox" id="metaso-AI" v-model="metasoAI">
          秘塔AI
        </label>
  
        <label for="translate-BD" class="checkbox-style">
          <input type="checkbox" id="translate-BD" v-model="translateBD">
          百度翻译
        </label>
  
        <label for="translate-DL" class="checkbox-style">
          <input type="checkbox" id="translate-DL" v-model="translateDEEPL">
          DeepL
        </label>
  
        <label for="Google-search" class="checkbox-style">
          <input type="checkbox" id="Google-search" v-model="searchgoogle">
          谷歌
        </label>
  
         <label for="ggduck-search" class="checkbox-style">
          <input type="checkbox" id="ggduck-search" v-model="searchggduck">
          Gogoduck
        </label>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        searchText: '',
        searchBaidu: true,
        searchBing: false,
        searchZhihu: false,
        metasoAI: true,
        translateBD: true,
        translateDEEPL: false,
        searchgoogle: false,
        searchggduck:false,
      };
    },
    methods: {
      search() {
        const query = this.searchText;
        if (this.searchBaidu) {
          const baiduUrl = `https://www.baidu.com/s?wd=${encodeURIComponent(query)}`;
          window.open(baiduUrl, '_blank');
        }
        if (this.searchBing) {
          const bingUrl = `https://www.bing.com/search?q=${encodeURIComponent(query)}`;
          window.open(bingUrl, '_blank');
        }
        if (this.searchZhihu) {
          const zhihuUrl = `https://www.baidu.com/s?wd=site:zhihu.com%20${encodeURIComponent(query)}`;
          window.open(zhihuUrl, '_blank');
        }
        if (this.metasoAI) {
          const metasoAIUrl = `https://metaso.cn/?q=${encodeURIComponent(query)}`;
          window.open(metasoAIUrl, '_blank');
        }
        if (this.translateBD) {
          const BDUrl = `https://fanyi.baidu.com/#zh/en/${encodeURIComponent(query)}`;
          window.open(BDUrl, '_blank');
        }
        if (this.translateDEEPL) {
          const DeepLUrl = `https://www.deepl.com/translator#zh/en/${encodeURIComponent(query)}`;
          window.open(DeepLUrl, '_blank');
        }
        if (this.searchgoogle) {
          const googleUrl = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
          window.open(googleUrl, '_blank');
        }
        if (this.searchggduck) {
          const ggduckUrl = `https://duckduckgo.com/?q=${encodeURIComponent(query)}`;
          window.open(ggduckUrl, '_blank');
        }
      },
    translate() {
      // 直接调用翻译相关的搜索
      this.searchBaidu = false;
      this.searchBing = false; 
      this.searchZhihu = false,
      this.translateBD = true; 
      this.translateDEEPL = true; 
      this.metasoAI = false;
      this.searchgoogle = false; 
      this.searchggduck = false;  
      this.search();
    },
    domesticSearch() {
      // 直接调用国内搜索相关的搜索
      this.searchBaidu = true;
      this.searchBing = true; 
      this.searchZhihu = true,
      this.translateBD = false; 
      this.translateDEEPL = false; 
      this.searchgoogle = false; 
      this.metasoAI = true;
      this.searchggduck = false;  
      this.search();
    },
    overseaSearch() {
      this.searchBaidu = false;
      this.searchBing = false; 
      this.searchZhihu = false,
      this.translateBD = false; 
      this.translateDEEPL = false; 
      this.metasoAI = false;
      this.searchgoogle = true;
      this.searchggduck = true;  
      this.search();
    },
  },
}
  </script>
  
<style scoped>
/* Container for overall layout */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Button group styling */
.button-group {
  margin-bottom: 20px; /* 增加与搜索框的间距 */
  margin-left: 30px;
}

.button-style {
  background-color: #fce23b; 
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px; /* 增加按钮间的间距 */
}

.button-style:last-child {
  margin-right: 0; /* 最后一个按钮不需要右边距 */
  margin-top: 20px;
}

.button-style:hover {
  background-color: #f9a620; /* Darker green on hover */
}

/* Input styling */
.input-style {
  width: 100%; /* Full container width */
  padding: 10px;
  margin: 10px 12px; /* 增加上下边距 */
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 1.25rem;
}

.input-style:focus {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Checkbox group styling */
.checkbox-group {
  display: flex; /* 使用弹性盒模型布局 */
  flex-wrap: wrap; /* 允许子项在必要时换行 */
  justify-content: flex-start; /* 子项从左侧开始排列，避免不必要的间隔 */
  align-items: center; /* 子项在交叉轴上居中对齐 */
  width: 130%; /* 容器宽度占满父元素的100% */
  margin-top: 50px; /* 容器顶部外边距 */
  margin-bottom: 30px; /* 容器底部外边距 */
}

.checkbox-style {
    border: none;
    padding: 10px;
    margin: 5px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}
.checkbox-style input[type="checkbox"] {
  margin-right: 5px; /* 复选框右侧的外边距，用于分隔复选框和标签文本 */
}

  </style>
  
